<template>
  <div class="transfer-cont">
    <div class="transfer-name">{{info.proname}}</div>

    <div class="info shadow form">
      <ul>
        <li class="item">
          <div class="label">购买人：</div>
          <div class="cont">{{info.purchaser}}</div>
        </li>

        <div class="item line">
          <div class="label">品牌型号：</div>
          <div class="cont" style="padding: 0;">
            <div class="select" @click="showBrand">
              <div class="select-text">
                <span v-show="! form.brand">请选择</span>
                <span v-show="form.brand">{{form.brand}}</span>
              </div>
              <div class="select-icon">
                <img src="@/assets/img/right.png" alt="">
              </div>
            </div>
          </div>
        </div>

        <li class="item">
          <div class="label">IMEI码：</div>
          <!-- <div class="cont">{{info.imel}}</div> -->
          <cube-input class="input" :clearable="false" placeholder="请输入" v-model.trim="form.imel" :maxlength="15"></cube-input>
        </li>
        <li class="item">
          <div class="label">服务时间：</div>
          <div class="cont">{{info.startTime | dayF}} ~ {{info.endTime | dayF}}</div>
        </li>
        <li class="item">
          <div class="label">购机时间：</div>
          <div class="cont" @click="showDatePicker">{{form.time}}</div>
        </li>
      </ul>
    </div>
    <div class="group-name">
      <div class="group-line">
        <div class="group-line-in"></div>
      </div>
      <div class="group-title">上传购买凭证</div>
    </div>
    <div class="push">
      <div class="title">请上传原服务期内购买的手机凭证</div>
      <div class="up-img">
        <UpFile 
          @updata="upFile"
          :img="form.url"
          :bg="bg"
          label=""
          :master="false"></UpFile>
      </div>
    </div>

    <div class="btns">
      <cube-button class="btn" @click="transfer">确认</cube-button>
    </div>

    <div class="modo" v-if="show">
      <div class="modo-in">
        <div class="modo-icon">
          <img src="@/assets/img/success.png" alt="">
        </div>
        <div class="modo-name">保证服转移成功</div>
        <div class="modo-title">
          可关注“橙保修”公众号进行IMEI修改、服务查询、申请理赔
        </div>
        <div class="modo-btn" @click="goNext">确定</div>
      </div>
    </div>

    <!-- 品牌型号选择 -->
    <brand ref="brand" @change="boxVal($event, 'brand')"></brand>

  </div>
</template>

<script>
import sq8 from '@/assets/img/sq8.png'
import UpFile from '@/components/UpFile2.vue'
import brand from '@/components/brand.vue'

export default {
  name: 'transfer',
  components: {
    UpFile,
    brand
  },
  data () {
    return {
      id: this.$route.params.id,
      imei: this.$cookies.get('imei'),
      bg: sq8,
      datePicker: '',
      info: {
        name: '',
      },
      form: {
        url: '',
        time: '2020-11-11',
        imel: '',
        brand: '',
      },
      show: false
    }
  },
  mounted () {
    this.getData();
    this.form.time = this.dateFormat();
  },
  methods: {
    /**
     * 品牌选择
     */
    showBrand() {
      this.$refs.brand.$refs.drawer.show()
    },
    /**
     * 组件值确定
     */
    boxVal (val, type) {
      this.form[type] = val;
    },
    /**
     * IMEI验证
     */
    isImei (str) {
      if(str == ''){
        this.$createToast({
          txt: '请输入IMEI',
          type: 'warn'
        }).show()
        return false;
      }else if(str == 'defaultImeiCode'){
        this.$createDialog({
          type: 'alert',
          title: '',
          content: '抱歉，当前IMEI为默认IMEI，无法申请，请操作修改后进行申请。如损坏手机可以操作并显示，请在拨号状态下按手机"*#06#"查看需申请服务手机IMEI码；如损坏手机无法显示，请拨打400-890-0001与客户工作人员联系',
          icon: ''
        }).show()
        return false;
      }else if((/^[A-Za-z0-9]+$/.test(str))){ 
        if(str.length > 15 || str.length < 14){
          this.$createDialog({
            type: 'alert',
            title: '',
            content: '抱歉，您输入的IMEI位数不足。如损坏手机可以操作并显示，请在拨号状态下按手机"*#06#"查看需申请服务手机IMEI码；如损坏手机无法显示，请拨打400-890-0001与客户工作人员联系',
            icon: ''
          }).show()
          return false;
        }
      }else{
        this.$createDialog({
          type: 'alert',
          title: '',
          content: '抱歉，您输入的IMEI格式不正确。如损坏手机可以操作并显示，请在拨号状态下按手机"*#06#"查看需申请服务手机IMEI码；如损坏手机无法显示，请拨打400-890-0001与客户工作人员联系',
          icon: ''
        }).show()
        return false;
      }
      return true
    },
    // 获取当前日期
    dateFormat (value) {
      let add0 = (m) => {
        return m < 10 ? '0' + m : m
      }
      var time = new Date()
      var y = time.getFullYear()
      var m = time.getMonth() + 1
      var d = time.getDate()
      return y + '-' + add0(m) + '-' + add0(d)
    },
    /**
     * 获取数据
     */
    getData () {
      const vm = this;
      if(! this.id){
        this.$createToast({
          txt: '无保单信息',
          type: 'error'
        }).show()
        return
      }
      let postData = {
        policyId: this.id,
        IMEL: this.imei
      }
      vm.$store.commit('updateLoader', true);
      this.$http
        .post("/policy/queryTransferMessage", postData)
        .then(function(response) {
          vm.$store.commit('updateLoader', false);
          if (response.data.header.code == "A1000") {
            if(response.data.body.list.length > 0){
              vm.info = response.data.body.list[0];
              if(vm.info.imei != vm.imei){
                
              }
            }
          } else {
            vm.$createToast({
              txt: response.data.header.msg,
              type: 'warn'
            }).show()
          }
        })
        .catch(function() {
          vm.$createToast({
            txt: '网络错误',
            type: 'error'
          }).show()
        });
    },
    /**
     * 确认转移
     */
    transfer () {
      const vm = this;
      if(! this.id){
        this.$createToast({
          txt: '无保单信息',
          type: 'error'
        }).show()
        return
      }else if(! this.form.url){
        this.$createToast({
          txt: '请上传购买凭证',
          type: 'warn'
        }).show()
        return
      }else if(! this.form.imel){
        this.$createToast({
          txt: '请输入新手机IMEI',
          type: 'warn'
        }).show()
        return
      }else if(! this.form.brand){
        this.$createToast({
          txt: '请选择手机品牌型号',
          type: 'warn'
        }).show()
        return
      }

      if(! this.isImei(this.form.imel)){
        return
      }

      let postData = {
        policyId: this.id,
        imel: this.form.imel,
        pic: this.form.url,
        time: this.form.time + ' 00:00:00',
        brand: this.form.brand
      }
      vm.$store.commit('updateLoader', true);
      this.$http
        .post("/policy/transfer", postData)
        .then(function(response) {
          vm.$store.commit('updateLoader', false);
          if (response.data.header.code == "A1000") {
            vm.show = true;
          } else {
            vm.$createToast({
              txt: response.data.header.msg,
              type: 'warn'
            }).show()
          }
        })
        .catch(function() {
          vm.$createToast({
            txt: '网络错误',
            type: 'error'
          }).show()
        });
    },
    goNext () {
      this.$router.go(-2)
    },
    /**
     * 打开时间选择
     */
    showDatePicker() {
      if (!this.datePicker) {
        this.datePicker = this.$createDatePicker({
          title: '选择日期',
          min: new Date(2020, 10, 1),
          max: new Date(),
          value: new Date(),
          onSelect: this.selectHandle,
        })
      }
      this.datePicker.show()
    },
    /**
     * 时间选择器-确定
     */
    selectHandle(date, selectedVal, selectedText) {
      this.form.time = `${selectedText.join('-')}`
    },
    /**
     * 更新图片
     */
    upFile (url) {
      this.form.url = url;
    },
    /**
     * 跳转页面
     */
    goPath (path) {
      this.$router.push(path)
    },
    /**
     * 返回页面
     */
    goBack () {
      this.$router.back(-1)
    },
  },
  filters: {
    dayF (str) {
      if(str){
        return str.substring(0, 10)
      }else{
        return ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.transfer-name{
  padding: 60px 30px;
  text-align: center;
  font-size: 48px;
  color: #208170;
  letter-spacing: 0.77px;
  text-align: center;
  line-height: 48px;
}
.info{
  margin: 0 30px 0px 30px;
  padding: 0 30px;
  .item{
    border-top: 1px solid #E6E6E6;
    &:first-child{
      border: none;
    }
    display: flex;
    
    font-family: PingFangSC-Regular;
    font-size: 30px;
    color: #333333;
    line-height: 30px;
    .label{
      padding: 30px 0;
    }
    .cont{
      padding: 30px 10px;
      flex: 10;
      text-align: right;
    }
    .input{
      flex: 10;
    }
  }
}
.select{
  display: flex;
  text-align: right;
  padding: 30px 0;
  .select-text{
    flex: 10;
  }
  .select-icon{
    padding-left: 18px;
    img{
      display: block;
      height: 30px;
      width: 18px;
    }
  }
}
.push{
  padding: 0 60px 200px 60px;
  .title{
    font-size: 28px;
    color: #999999;
    line-height: 30px;
    margin-bottom: 30px;
  }
  .up{
    width: 200px;
    height: 180px;
    border: 1px solid #999999;
    border-radius: 14px;
    position: relative;
    img{
      display: block;
      height: 141px;
      margin-top: 11px;
    }
    .up-title{
      position: absolute;
    }
  }
}
.up-img{
  img{
    display: block;
    height: 200px;
  }
}

.btns{
  position: absolute;
  bottom: 49px;
  left: 0;
  width: 100%;
  .btn{
    width: 540px;
    height: 80px;
    padding: 0;
    background: #208170;
    border-radius: 50px;
    margin: 0 auto;
  }
}
</style>